

























import { Component, Vue, Watch } from "vue-property-decorator";
import { ProductConsultationsDto } from "@/api/appService";
import api from "@/api";
import { CodeToText } from "element-china-area-data";

@Component({
  name: "foundationDetail",
})
export default class FoundationDetail extends Vue {
  detail: ProductConsultationsDto = {};
  styles = "";
  filename = "产品咨询详情";
  selector = "table-detail-world";

  created() {
    if (this.$route.params.id) {
      this.fetchDetail();
    }
  }

  @Watch("$route", { deep: true })
  onRouteChange() {
    this.fetchDetail();
  }

  // 获取详情
  async fetchDetail() {
    api.productConsultations
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.detail = { ...res };
      });
  }
  // 返回
  private cancel() {
    this.$router.back();
  }
}
